<template>
  <el-card class="box-card">
    <div slot="header">
      <h3 class="inBlock">商品分类</h3>
    </div>
    <div class="box">
      <div class="top">
        <el-button
          type="info"
          size="mini"
          icon="el-icon-plus"
          plain
          @click="dialogaAddClass = true"
          >添加分类</el-button
        >
        <!-- <p><input type="text" v-model="seach.name" /> <span>搜索</span></p> -->
      </div>

      <el-table
        :tree-props="tableprops"
        row-key="id"
        :data="classlistAll"
        border
        style="width: 100%"
      >
        <el-table-column prop="id" label="分类id">
        </el-table-column>
        <el-table-column prop="name" label="分类名称">
        </el-table-column>
        <el-table-column prop="sort" label="分类排序"> </el-table-column>
        <!-- <el-table-column label="分类级别">
          <div slot-scope="scope">
            <el-tag v-if="" size="mini" style="margin-left: 10px">一级分类</el-tag>
          </div>
        </el-table-column> -->
        <el-table-column label="编辑">
          <div slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="点击编辑分类"
              placement="top"
            >
              <span
                @click.stop.prevent
                @click="cat_bianji(scope.row)"
                class="span shou"
                ><i class="el-icon-edit-outline"></i
              ></span>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="点击删除分类"
              placement="top"
            >
              <span
                @click.stop.prevent
                class="span shou"
                @click="cat_del(scope.row.id)"
                ><i class="el-icon-delete"></i
              ></span>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="添加分类" :visible.sync="dialogaAddClass">
      <el-form :model="addform" ref="addform" :rules="rules">
        <el-form-item label="分类名称:" label-width="100px" prop="name">
          <el-input
            v-model="addform.name"
            style="width: 200px"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="父级分类:" label-width="100px">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link shou">
              {{ Classname }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="selectClass"
                >一级分类</el-dropdown-item
              >
              <el-dropdown-item
                v-for="(item, index) in classlistAll"
                :key="index"
                @click.native="selectClass(item)"
                >{{ item.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <span style="font-size: 12px; color: #009688"
            >不选则默认是1级分类</span
          >
        </el-form-item>
        <el-form-item label="分类排序:" label-width="100px">
          <el-input
            type="number"
            v-model="addform.sort"
            style="width: 220px"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogaAddClass = false">取 消</el-button>
        <el-button type="primary" @click="addSubmit"> 添 加</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  shopclass,
  addshopclass,
  catdetail,
  getcatAll,
  delgoods_cat,
} from "@/api/api.js";
export default {
  name: "",
  data() {
    return {
      tableprops: {
        children: "childer",
      },
      activeNames: "",
      dialogaAddClass: false,
      classlist: [],
      classlistAll: [],
      Classname: "一级分类",
      seach: {
        name: "",
      },
      addform: {
        name: "",
        parent_id: 0,
        sort: "", // 分类排序
      },

      rules: {
        name: [{ required: true, message: "请填写分类名称", trigger: "blur" }],
      },
    };
  },
  watch: {
    dialogaAddClass: function (newShow, oldShow) {
      if (newShow == false) {
        delete this.addform.id;
        (this.addform.name = ""),
          (this.addform.parent_id = 0),
          (this.addform.sort = "");
      }
    },
  },
  methods: {
    cat_bianji(item, item2) {
      if (item2) {
        this.addform.name = item2.name;
        this.addform.parent_id = item.id;
        this.addform.id = item2.id;
        this.addform.sort = item2.sort;
        this.Classname = item.name;
        this.dialogaAddClass = true;
      } else {
        this.addform.name = item.name;
        this.addform.parent_id = 0;
        this.addform.id = item.id;
        this.addform.sort = item.sort;
        this.dialogaAddClass = true;
      }
    },
    async cat_del(id) {
      this.$confirm("您真的要删除该分类吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delgoods_cat({ id });
          if (data.code != 200) return this.$message.error(data.data);
          this.getcatAllList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    selectClass(item) {
      if (item.id) {
        this.addform.parent_id = item.id;
        this.Classname = item.name;
        console.log(this.addform.parent_id);
      } else {
        this.addform.parent_id = 0;
        this.Classname = "一级分类";
        console.log(this.addform.parent_id);
      }
    },
    async addSubmit() {
      const { data } = await addshopclass(this.addform);
      if (data.code != 200) return this.$message.error("添加失败, 请稍后重试!");
      this.$message.success("添加分类成功");
      this.dialogaAddClass = false;
      this.getcatAllList();
    },
    async detaile(id) {
      const { data } = await catdetail({ id });
      console.log(data);
    },
    async getcatAllList() {
      const { data } = await getcatAll();
      this.classlistAll = data.data.cat_list;
      console.log(this.classlistAll);
    },
  },
  created() {
    this.getcatAllList();
  },
};
</script>

<style scoped lang='less'>
.box-card {
  flex: 1;
  margin-bottom: 10px;
  .box {
    .top {
      padding: 10px;
      p {
        border: 1px solid #0a96fe;
        margin-top: 10px;
        width: 300px;
        display: flex;
        input {
          width: 245px;
          height: 30px;
          border: none;
        }
        span {
          line-height: 30px;
          text-align: center;
          width: 55px;
          background-color: #0a96fe;
          color: #fff;
        }
      }
    }
    .compile {
      span {
        background-color: #319ce0;
        padding: 2px 5px;
        margin: 0 5px;
        color: #fff;
        border-radius: 3px;
        &:nth-child(2) {
          background-color: #d90013;
        }
        &:nth-child(3) {
          background-color: #26c4a4;
        }
        &:last-child {
          background-color: #1aad19;
        }
      }
    }
  }
  .span {
    padding: 1px 5px;
    font-size: 10px;
    margin: 0 5px;
    border-radius: 5px;
    color: #fff;
    background-color: #1aad19;
    &:last-child {
      background-color: #d90013;
    }
  }
}
</style>